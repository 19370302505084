import alertNotification from '../../utils/alertNotification';
import httpClient from '../../utils/httpClient';
import { v4 as uuidv4 } from 'uuid';
import LoadingModal from '../common/LoadingModal';
import { useState } from 'react';

// const US = [
//   {
//     id: 'small',
//     service_code: 'usps_ground_advantage',
//     title: '2-5 Gün',
//   },
//   {
//     id: 'usps_priority',
//     service_code: 'usps_priority_mail',
//     title: '1-2 Gün',
//   },
//   {
//     id: 'upsnextday',
//     service_code: 'ups_next_day_air',
//     title: '1 Gün',
//   },
// ];
// const INTERNATIONAL = [
//   {
//     id: 'international',
//     service_code: 'usps_first_class_mail_international',
//     title: 'USPS First Class Mail International',
//   },
// ];

const titles = {
  usps_ground_advantage: '(USPS 2-5 İş Günü)',
  usps_priority_mail: '(USPS 1-3 İş Günü)',
  ups_next_day_air: '(USPS 1 İş Günü',
  usps_first_class_mail_international: '(USPS 3-7 İş Günü)',
  ups_ground_saver: '(UPS 3-7 İş Günü)',
  ups_standard_international: '(UPS 3-7 İş Günü)',
  'shipentegra-international-express': '(2-4 İş Günü)',
  'shipentegra-ups-express': '(1-4 İş Günü)',
  'shipentegra-express': '(2-5 İş Günü)',
};

interface Props {
  serviceCode: string;
  allShipmentCost: {
    serviceCode: string;
    shipmentCost: number;
    serviceDescription: string;
    carrierCode: string;
  }[];
  label: string;
  country: string;
  sellerOrderId: number;
  groupIndex: number;
  getOrders: () => void;
}
const ShipmentRadioGroup: React.FC<Props> = ({
  allShipmentCost,
  serviceCode,
  sellerOrderId,
  groupIndex,
  getOrders,
  country,
  label,
}) => {
  const [loading, setLoading] = useState(false);

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    const shippingData = {
      serviceCode: event.target.value,
      sellerOrderId,
    };

    try {
      await httpClient.patch('/api/v1/marketplace/order-shipping-service', {
        shippingData,
      });
      getOrders();
    } catch (error: any) {
      alertNotification('error', error.response.data);
    } finally {
      setLoading(false);
    }
  };
  const isShipentegra = allShipmentCost.some((plan) =>
    plan.serviceCode.includes('shipentegra')
  );

  const costArr: any[] = [];

  if (country === 'US') {
    allShipmentCost.map((plan) => {
      if (
        (plan.carrierCode === 'ECO' &&
          plan.serviceCode.includes('shipentegra')) ||
        (plan.carrierCode === 'EXPRESS' &&
          plan.serviceCode === 'shipentegra-ups-express') ||
        (plan.carrierCode === 'EXPRESS' &&
          plan.serviceCode === 'shipentegra-express')
      ) {
        if (plan.serviceCode !== 'shipentegra-widect') {
          costArr.push(plan);
        }
      }
    });
  } else {
    costArr.push(allShipmentCost[0]);
    allShipmentCost.map((plan) => {
      if (
        plan.serviceCode.includes('shipentegra-international-express') ||
        plan.serviceCode.includes('shipentegra-ups-express')
      ) {
        costArr.push(plan);
      }
    });
  }

  return (
    <fieldset className="w-32">
      <div className="space-y-2">
        {isShipentegra
          ? costArr?.map((plan: any, key: number) => (
              <div key={uuidv4()} className="relative flex items-start">
                <div className="flex h-6 items-center">
                  <input
                    name={`shipment-${groupIndex}`}
                    defaultChecked={plan.serviceCode === serviceCode}
                    value={plan.serviceCode}
                    onChange={handleChange}
                    disabled={
                      label !== 'waitingPayment' && label !== 'waitingMatch'
                    }
                    type="radio"
                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  />
                </div>
                <div className="ml-3 text-xs leading-6 w-32">
                  <p className=" text-gray-900">
                    ${plan.shipmentCost.toFixed(2)}
                    {/* {` (${plan.serviceDescription.match(pattern)?.[0] || ''})`} */}
                    {key === 0 && plan.serviceCode.includes('shipentegra')
                      ? ' (3-7 iş günü)'
                      : ` ${titles[plan.serviceCode as keyof typeof titles]}`}
                  </p>
                </div>
              </div>
            ))
          : allShipmentCost.map((plan) => (
              <div key={uuidv4()} className="relative flex items-center">
                <div className="flex h-6 items-center">
                  <input
                    disabled={
                      label !== 'waitingPayment' && label !== 'waitingMatch'
                    }
                    name={`shipment-${groupIndex}`}
                    defaultChecked={plan.serviceCode === serviceCode}
                    value={plan.serviceCode}
                    onChange={handleChange}
                    type="radio"
                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  />
                </div>
                <div className="ml-4 text-xs leading-8 w-36">
                  <p className=" text-gray-900">
                    ${plan.shipmentCost.toFixed(2)}
                    {` ${titles[plan.serviceCode as keyof typeof titles]}`}
                  </p>
                </div>
              </div>
            ))}
      </div>
      <LoadingModal
        text="Sipariş eşleştirme işlemi yapılıyor..."
        isModalOpen={loading}
        setIsModalOpen={setLoading}
      />
    </fieldset>
  );
};
export default ShipmentRadioGroup;
