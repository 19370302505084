import React, { useEffect, useState } from 'react';
import { Stage, Layer, Image as KonvaImage } from 'react-konva';
import useImage from 'use-image';
import DesignDropZone from './DesignDropZone';
import DesignItem from './DesignItem';
import { classNames } from '../../utils/conditionalClasses';
import axios from 'axios';
import API_URL from '../../config';
import alertNotification from '../../utils/alertNotification';
import { v4 as uuidv4 } from 'uuid';
import { ToastContainer } from 'react-toastify';

const designImages: { [key: string]: { [key: string]: string } } = {
  hoodie: {
    front: '/images/design-mockups/hoodie-front.png',
    back: '/images/design-mockups/hoodie-back.png',
    left: '/images/design-mockups/hoodie-left.png',
    right: '/images/design-mockups/hoodie-right.png',
  },
  tshirt: {
    front: '/images/design-mockups/tshirt-front.png',
    back: '/images/design-mockups/tshirt-back.png',
    left: '/images/design-mockups/tshirt-left.png',
    right: '/images/design-mockups/tshirt-right.png',
  },
  sweatshirt: {
    front: '/images/design-mockups/sweatshirt-front.png',
    back: '/images/design-mockups/sweatshirt-back.png',
    left: '/images/design-mockups/sweatshirt-left.png',
    right: '/images/design-mockups/sweatshirt-right.png',
  },
  carpet: {
    front: '/images/carpet.png',
  },
  stairtread: {
    front: '/images/stairtread.png',
  },
  roundedcarpet: {
    front: '/images/roundedcarpet.png',
  },
  cuttingBoard: {
    front: '/images/cutting-board.webp',
  },
  ornament: {
    front: '/images/ornament.webp',
  },
  ornamentHeart: {
    front: '/images/ornament-heart.webp',
  },
  yardSign: {
    front: '/images/signyard.webp',
  },
  jewelry: {
    front: '/images/jewelry.png',
  },
  mug: {
    front: '/images/mug.webp',
  },
  doormat: {
    front: '/images/doormat.webp',
  },
  hat: {
    front: '/images/hat.webp',
  },
  canvas: {
    front: '/images/canvas.webp',
  },
  totebag: {
    front: '/images/totebag.webp',
  },
  default: {
    front: '/images/default.webp',
  },
};

interface Props {
  selectedProductId: any;
  getOrders: () => void;
  setOpen: (open: boolean) => void;
  isUpdate: boolean;
  isImageSaved: boolean;
  isCustom: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedOrderItemId: number;
  addedFrom: string;
}
const DesignCanvas: React.FC<Props> = ({
  selectedProductId,
  getOrders,
  setOpen,
  isUpdate,
  isCustom,
  isImageSaved,
  setIsModalOpen,
  selectedOrderItemId,
  addedFrom,
}) => {
  const [productType, setProductType] = useState('tshirt');
  const [loading, setLoading] = useState(false);
  const [selectedSide, setSelectedSide] = useState('front');
  const [originalFile, setOriginalFile] = useState<any>(null);

  const [tshirtImage] = useImage(
    designImages[productType][selectedSide] || '/images/t-shirt-front.png'
  );
  const [selectedId, selectShape] = React.useState<any>(null);
  const [localImageSaved, setLocalImageSaved] = useState(!isImageSaved);
  const [image, setImage] = useState<any>(null);

  const [design, setDesign] = useState<any>({
    x: 100,
    y: 100,
    width: 200,
    height: 200,
  });
  // const handleCheckboxChange = async (
  //   event: React.ChangeEvent<HTMLInputElement>,
  //   sellerProductId: number
  // ) => {
  //   const isChecked = event.target.checked;
  //   try {
  //     // Sunucuya isteği gönder
  //     await httpClient.patch('/api/v1/marketplace/seller/isImageSaved', {
  //       sellerProductId,
  //       isImageSaved: !isChecked,
  //     });
  //     setLocalImageSaved(isChecked);
  //   } catch (error: any) {
  //     alertNotification('error', error.response.data);
  //   }
  // };
  const stageRef = React.useRef<any>(null);

  let http = axios.create({
    headers: {
      'Content-type': 'multipart/form-data',
    },
    baseURL: API_URL,
    withCredentials: true,
  });

  const handleExport = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    selectShape(null);
    setLoading(true);
    const uri = stageRef.current.toDataURL();
    const blob = await (await fetch(uri)).blob();

    const formData = new FormData();
    const maxSize = 10 * 1024 * 1024; // 10 MB
    if (blob.size > maxSize) {
      alertNotification(
        'error',
        'Yüklenilen dosya çok büyük. Maksimum boyut 10 MB.'
      );
      return;
    }

    if (image.size > maxSize) {
      alertNotification(
        'error',
        'Yüklenilen dosya çok büyük. Maksimum boyut 10 MB.'
      );
      return;
    }

    if (originalFile) {
      formData.append('designFile', originalFile, `designPDF__${uuidv4()}.pdf`);
    } else {
      formData.append('designFile', image, `design__${uuidv4()}.png`);
    }
    formData.append('mockupFile', blob, `mockup__${uuidv4()}.png`);

    try {
      // if (isUpdate) {
      //   await http.patch(
      //     `/api/v1/marketplace/order/design/${selectedProductId}?orderItemId=${selectedOrderItemId}&addedFrom=orders`,
      //     formData
      //   );
      // } else if (!isUpdate) {
      await http.post(
        `/api/v1/marketplace/order/design/${selectedProductId}?orderItemId=${selectedOrderItemId}&addedFrom=${addedFrom}`,
        formData
      );
      // }

      getOrders();
    } catch (error: any) {
      alertNotification('error', error.response.data);
    } finally {
      setLoading(false);
      setOpen(false);
      setIsModalOpen(false);
    }
  };

  const imageRef = React.useRef<any>(null);
  useEffect(() => {
    if (tshirtImage) {
      const aspectRatio = tshirtImage.width / tshirtImage.height;
      let newWidth = tshirtImage.width;
      let newHeight = tshirtImage.height;

      if (tshirtImage.width > 400 || tshirtImage.height > 400) {
        if (aspectRatio > 1) {
          newWidth = 400;
          newHeight = 400 / aspectRatio;
        } else {
          newHeight = 400;
          newWidth = 400 * aspectRatio;
        }
      }

      imageRef.current.width(newWidth);
      imageRef.current.height(newHeight);
      imageRef.current.x((400 - newWidth) / 2.1);
      imageRef.current.y((400 - newHeight) / 3);
    }
  }, [tshirtImage]);
  return (
    <div className="flex flex-col space-y-2 items-center overflow-y-auto">
      <ToastContainer />
      <select
        className="w-full rounded-md border border-cardBorder py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
        name="productType"
        id="productType"
        onChange={(e) => {
          setProductType(e.currentTarget.value);
        }}
      >
        <option value="tshirt">T-shirt</option>
        <option value="hoodie">Hoodie</option>
        <option value="sweatshirt">Sweatshirt</option>
        <option value="carpet">Halı</option>
        <option value="roundedcarpet">Yuvarlak Halı</option>
        <option value="stairtread">Basamak Halı</option>
        <option value="yardSign">Yard Sign</option>
        <option value="ornament">Ornament</option>
        <option value="ornamentHeart">Ornament-Kalp</option>
        <option value="cuttingBoard">Kesme Tahtası</option>
        <option value="jewelry">Takı</option>
        <option value="mug">Bardak</option>
        <option value="doormat">Paspas</option>
        <option value="hat">Şapka</option>
        <option value="canvas">Kanvas-Tablo</option>
        <option value="totebag">Çanta</option>
        <option value="default">Genel</option>
      </select>
      <Stage width={400} height={400} ref={stageRef}>
        <Layer>
          <KonvaImage
            image={tshirtImage}
            x={0}
            ref={imageRef}
            y={0}
            width={400}
            height={400}
          />
          <DesignItem
            image={image}
            shapeProps={design}
            isSelected={selectedId === 1}
            onSelect={() => {
              selectShape(1);
            }}
            onChange={(attr: any) => {
              setDesign(attr);
              selectShape(null);
            }}
          />
        </Layer>
      </Stage>
      <div className="flex justify-center items-center space-x-2">
        <button
          onClick={() => setSelectedSide('front')}
          className={classNames(
            selectedSide === 'front'
              ? 'bg-headerPrimary text-white border-2 border-headerPrimary'
              : 'bg-white border-headerPrimary border-2 text-headerPrimary',
            'w-24 rounded-full py-1'
          )}
        >
          Ön Yüz
        </button>
        {(productType === 'tshirt' ||
          productType === 'hoodie' ||
          productType === 'sweatshirt') && (
          <button
            onClick={() => setSelectedSide('back')}
            className={classNames(
              selectedSide === 'back'
                ? 'bg-headerPrimary text-white'
                : 'bg-white border-headerPrimary border-2 text-headerPrimary',
              'w-24 rounded-full py-1'
            )}
          >
            Arka Yüz
          </button>
        )}
        {(productType === 'tshirt' ||
          productType === 'hoodie' ||
          productType === 'sweatshirt') && (
          <button
            onClick={() => setSelectedSide('right')}
            className={classNames(
              selectedSide === 'right'
                ? 'bg-headerPrimary text-white'
                : 'bg-white border-headerPrimary border-2 text-headerPrimary',
              'w-24 rounded-full py-1'
            )}
          >
            Sağ Kol
          </button>
        )}
        {(productType === 'tshirt' ||
          productType === 'hoodie' ||
          productType === 'sweatshirt') && (
          <button
            onClick={() => setSelectedSide('left')}
            className={classNames(
              selectedSide === 'left'
                ? 'bg-headerPrimary text-white'
                : 'bg-white border-headerPrimary border-2 text-headerPrimary',
              'w-24 rounded-full py-1'
            )}
          >
            Sol Kol
          </button>
        )}
      </div>

      <DesignDropZone setImage={setImage} setOriginalFile={setOriginalFile} />
      {/* <div className="flex space-x-2">
        <input
          id="comments"
          name="comments"
          type="checkbox"
          aria-describedby="comments-description"
          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
          // checked={prod.isImageSaved}
          checked={localImageSaved}
          onChange={(e) => handleCheckboxChange(e, +selectedProductId)}
        />
        <label className="text-sm text-headingPrimary" htmlFor="comments">
          Ürün custom design (kişiselleştirilmiş) üründür
        </label>
      </div> */}
      <button
        disabled={loading}
        className="bg-blue-500 p-2 rounded-lg text-white w-32"
        onClick={handleExport}
      >
        {loading ? 'Yükleniyor...' : 'Kaydet'}
      </button>
    </div>
  );
};

export default DesignCanvas;
